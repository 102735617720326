import React from 'react';

import { Image as AntdImage } from 'antd';
import Image from 'next/legacy/image';

import styles from './FeedImage.module.css';

const FeedImage = (props) => {
  const { src, width = 'auto', height = 'auto', className = '', style = {}, preview = false, alt = 'kikitrade' } = props;
  return (
    <AntdImage
      src={src}
      width={width}
      height={height}
      className={[styles.feedImage, className]}
      style={{ ...style, width, height }}
      preview={preview}
      alt={alt}
      placeholder={
        <Image
          className={styles.feedImage}
          src={require('../../images/kiki_preload.webp')}
          layout='fill'
          alt={alt}
        />
      }
   />
  )
}

export default FeedImage;
