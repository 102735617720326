import React, { memo, useCallback, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';

import { getClient } from '@api/feedStream';
import { SocialAPI } from '@api/social';
// import p from '@utils/Transfrom';
import { PostItemContentLoading } from '@components/ContentLoading';
import EmptyComponent from '@components/EmptyComponent';
import { ScrollLoading } from '@components/index';
import PostComponent, { DeletedPost } from '@components/PostComponent/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect';
import pubsub from '@lib/scripts/pubsub';
import helper from '@utils/helper';
import I18n from '@utils/i18n';
import jwtToken from '@utils/jwtToken';

import styles from './index.module.css';

const defaultLimit = 6;

const SocialListItemSeparator = () => {
  return (
    // Item Separator
    <div className={styles.itemSeparator} />
  );
};

function SocialFeed (props) {
  const {
    feedParamUser = 'post',
    feedParamType = 'recommend',
    limit = defaultLimit,
    feedType,
    topicId,
    userId,
    topicType,
    tab,
    isLoadMore = true,
    page
  } = props;
  const [currentFeeds, setCurrentFeeds] = useState([]);
  const currentFeedsRef = useRef([]);
  const nextIdRef = useRef('');
  const [loading, setLoading] = useState(true);
  const [moreStatus, setMoreStatus] = useState(true);
  useIsomorphicLayoutEffect(() => {
    fetchFeed({}, () => { });
  }, [topicId, userId]);

  const quickPostDone = () => {
    if (tab !== 'recent') {
      return;
    }
    fetchFeed({}, () => { });
  };
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('QUICK_POST_DONE', quickPostDone);
    return () => {
      pubsub.unSubscribe('QUICK_POST_DONE', quickPostDone);
    };
  }, []);
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('UPDATE_SOCIAL_FEED', fetchFeed);
    return () => {
      pubsub.unSubscribe('UPDATE_SOCIAL_FEED', fetchFeed);
    };
  }, []);
  const successFun = (body, options, callback) => {
    let updateFeed = [];
    if (options && options.loadMore) {
      updateFeed = currentFeedsRef.current.concat(
        helper.setTitleAndText(body.result)
      );
    } else {
      updateFeed = helper.setTitleAndText(body.result);
    }
    currentFeedsRef.current = updateFeed;
    setCurrentFeeds(currentFeedsRef.current);
    // setNextId(body.nextId)
    nextIdRef.current = body.nextId;
    setLoading(false);
    setMoreStatus('success');
    callback && callback();
  };
  // topic详情页面feed流
  const fetchTopciFeed = useCallback((options, callback) => {
    const queryParam = {
      topicId,
      type: topicType, // 'hot' : 'newest'
      limit: limit || defaultLimit,
      language: I18n.locale,
      nextToken: options ? options.nextId : ''
    };
    const opts = {
      Authorization: jwtToken.getToken()
    };
    SocialAPI.topicPostFeed(queryParam, opts)
      .then((body) => {
        successFun(body, options, callback);
      })
      .catch((error) => {
        console.log('error');
      });
  });
  // 个人主页收藏feed流
  const fetchFavoriteFeed = useCallback((options, callback) => {
    const requestParams = {
      limit: limit || defaultLimit,
      id_lte: options ? options.nextId : '',
      user_id: props.userId,
      kind: 'FAVORITE',
      version: 2
    };
    getClient()
      .reactions.filter(requestParams)
      .then((body) => {
        successFun(body, options, callback);
      });
  });

  const fetchFeed = useCallback((options, callback) => {
    if (feedType === 'favorite') {
      fetchFavoriteFeed(options, callback);
    } else if (feedType === 'topic') {
      fetchTopciFeed(options, callback);
    } else {
      const queryParam = {
        limit: limit || defaultLimit,
        id_lte: options ? options.nextId : ''
      };
      getClient()
        .feed(feedParamUser, feedParamType)
        .get(queryParam)
        .then((body) => {
          successFun(body, options, callback);
        })
        .catch((error) => {
          setMoreStatus('success');
        });
    }
  });

  const fetchMoreFeed = useCallback((unObserver) => {
    if (isLoadMore) {
      if (nextIdRef.current) {
        setMoreStatus('loading');
        fetchFeed({ loadMore: true, nextId: nextIdRef.current });
      }
    } else {
      unObserver();
      return Promise.resolve(true);
    }
  });

  // 对收藏页面被删除的帖子执行取消收藏操作
  const onDelete = (item) => {
    currentFeedsRef.current = currentFeedsRef.current.filter(
      (cacheItem) => cacheItem.reactionId !== item.reactionId
    );
    setCurrentFeeds(currentFeedsRef.current);
  };

  const scrollContent = useMemo(() => {
    if (currentFeeds.length) {
      return uniqBy(currentFeeds, 'id').map((item, index) => {
        return (
          <div key={index}>
            {item.deleted
              ? (
                <DeletedPost
                  onDelete={onDelete}
                  postData={item}
                  className={cn({ [styles.deletedPost]: !index })}
                />
                )
              : (
                <PostComponent
                  key={item.id}
                  keyIndex={item.id}
                  itemData={item}
                  page={page}
                />
                )}
            {index !== currentFeeds.length - 1
              ? (
                <SocialListItemSeparator />
                )
              : null}
          </div>
        );
      });
    }
    return (
      <EmptyComponent
        desc={I18n.t('noFeed')}
        style={{ alignItems: 'center' }}
        source={require('@images/noFeed.webp')}
      />
    );
  }, [fetchMoreFeed, currentFeeds, page]);

  const LoadingContent = () => {
    const data = [1, 2, 3, 4];
    return data.map((item) => (
      <div key={item}>
        <div
          className={styles.loadingContent}
        // style={{
        //   marginleft: p(0),
        //   marginRight: p(0),
        //   backgroundColor: '#fff',
        // }}
        >
          <PostItemContentLoading />
        </div>
        {item !== data.length ? <SocialListItemSeparator /> : null}
      </div>
    ));
  };

  const container = useMemo(() => {
    if (loading) {
      return <LoadingContent />;
    }
    return (
      <ScrollLoading
        fetchData={fetchMoreFeed}
        invisibleHeight={300}
        status={moreStatus}
      >
        {scrollContent}
        <div className={styles.scrollFooter}></div>
      </ScrollLoading>
    );
  }, [loading, fetchMoreFeed, currentFeeds, page]);

  return <div>{container}</div>;
}
const MemoSocialFeed = memo(SocialFeed);

// export default inject(rootStore => {
//   return {
//     userStore: rootStore.store.userStore
//   }
// })(observer(SocialFeed))
export default MemoSocialFeed;
