import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import pubsub from '@lib/scripts/pubsub';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import { useFav } from '@widget/post/hooks/fav';
import { useLike } from '@widget/post/hooks/like';
import ActionGroup from '../Details/Components/ActionGroup';
import styles from './index.module.css';
import useClient from '@lib/hooks/useClient';

const T = ({ itemData, userStore }) => {
  const [postData, setPostData] = useState(itemData);
  const [curLikeCount, setCurLikeCount] = useState(itemData.likeCount);
  const [isLoved, setIsLoved] = useState(itemData.like);
  const [curFavCount, setCurFavCount] = useState(itemData.favoriteCount);
  const [isFav, setIsFav] = useState(itemData.favorite);
  const [curShareCount, setCurShareCount] = useState(itemData.shareCount);
  const isPc = useClient();
  const { like } = useLike({
    userStore,
    itemData,
    setCurLikeCount,
    setIsLoved,
    isLoved,
    curLikeCount
  });
  const { onFavoirite } = useFav({
    itemData,
    setCurFavCount,
    setIsFav,
    isFav,
    curFavCount
  });
  const commentFun = () => {
    pubsub.publish('COMMENT_INPUT_STATE', { postId: itemData.id });
  };
  const updateCommentCount = ({ postId, commentCount }) => {
    if (postId === postData.id) {
      setPostData({
        ...postData,
        commentCount
      });
    }
  };
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('POST_COMMENT_COUNT', updateCommentCount);
    return () => {
      pubsub.unSubscribe('POST_COMMENT_COUNT', updateCommentCount);
    };
  }, [postData]);
  return (
    <div className={styles.container}>
      <ActionGroup
        className={styles.actionGroup}
        likeClass={styles.action}
        favClass={styles.action}
        commentClass={styles.action}
        shareClass={styles.action}
        itemClass={styles.item}
        curLikeCount={curLikeCount} // 点赞数量
        isLoved={isLoved} // 是否点赞
        isFav={isFav} // 是否收藏
        curShareCount={curShareCount} // 分享次数
        curFavCount={curFavCount} // 收藏次数
        itemData={postData}
        setCurShareCount={setCurShareCount}
        likeFun={like}
        favFun={onFavoirite}
        commentFun={commentFun}
        placement={isPc ? 'topLeft' : 'top'}
        commentCount={postData.commentCount}
      />
      <div className={styles.more}></div>
    </div>
  );
};

export const FeedActionGroup = inject((rootStore) => {
  return {
    userStore: rootStore.store.userStore
  };
})(observer(T));
