import React, { useCallback } from 'react';

import cn from 'classnames';
import Image from 'next/legacy/image';
import Router from 'next/router';

import { COMMUNITY_PATHS } from '@constants/index';

import styles from './index.module.css';

const T = (props) => {
  const { itemData, type, onDelete } = props

  const jump = useCallback(() => {
    if (type !== 'editor') {
      Router.push(`${COMMUNITY_PATHS.TOPIC}${itemData?.topicId}`)
    }
  }, [])

  if (!(itemData?.topicId)) {
    return <div />;
  }

  return (
    <div className={styles.topicSection} onClick={jump}>
        <a className={styles.topicSectionA}>
          <div className={styles.topicSectionBtn} data-name='topic-tag-btn'>
            <span className={styles.topicTagImage}>
              <Image
                src={require('@images/topic_tag.webp')}
                width={25}
                height={22}
                layout='fixed'
                alt={itemData?.topicContent || ' '}
              />
            </span>
              <p className={cn(styles.topicTagText, 'DINPro')}>
                {itemData?.topicContent}
            </p>
            {
              type === 'editor' && (
              <div className={styles.closeIcon} onClick={onDelete}>
                <Image
                  src={require('@images/clear.webp')}
                  width={14}
                  height={14}
                  layout='fixed'
                  alt={itemData?.topicContent || ' '}
                />
              </div>
              )
            }
          </div>
        </a>
    </div>
  )
}

export default T;
