import React from 'react';
import { Button } from '@element/index'
import cn from 'classnames';
import { I18n } from '@utils/index'
import { useFav } from '@widget/post/hooks/fav'
import styles from './index.module.css';

export const DeletedPost = (props) => {
  const { className, onDelete, postData } = props;
  const { onFavoirite } = useFav({ itemData: postData, setCurFavCount: () => {}, setIsFav: () => {}, isFav: true, curFavCount: 1, idKey: 'reactionId' })
  return (
    <div className={cn(styles.deletedPost, className)}>
      <div className={styles.desc}>{I18n.t('originText_deleted1')}</div>
      <Button type="ghost" className={cn(styles.delBtn)} onClick={() => {
        onFavoirite();
        onDelete(postData)
      }} borderRadius={8}>
        <span className={'Medium'}>{I18n.t('deleteConfirm')}</span>
      </Button>
    </div>
  )
}
