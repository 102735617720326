import { useState } from 'react';
import useAuthSession from '@lib/hooks/useAuthSession'
import { message } from 'antd';
import { getClient } from '@api/feedStream'
import { I18n, getLoginPath } from '@utils/index'
import { useRouter } from 'next/router'
// 收藏 取消收藏
export const useFav = ({ itemData = {}, setCurFavCount, setIsFav, isFav, curFavCount, idKey = 'id' }) => {
  const toastKey = 'updatable';
  const router = useRouter();
  const isLogin = useAuthSession({ redirectTo: null })
  const [sending, setSending] = useState(false)

  const onFavoirite = () => {
    // 没登录跳登录页
    if (!isLogin) {
      router.push(getLoginPath())
      return
    }
    if (sending) {
      message.loading({ content: I18n.t('common_submiting'), key: toastKey })
    }
    setSending(true)
    getClient()
      .reactions.add(
        'FAVORITE',
        itemData[idKey],
        isFav ? { reverse: true } : {}
      )
      .then((body) => {
        setSending(false)
        if (body && body.result) {
          const msg1 = isFav ? I18n.t('community_cancelFavSuccess') : I18n.t('community_favSuccess')
          message.success({ content: msg1, key: toastKey, duration: 3 })
          setCurFavCount(isFav ? curFavCount - 1 : curFavCount + 1);
          setIsFav(!isFav);
        }
      })
      .catch((error) => {
        console.log(error)
        setSending(false)
        try {
          if (error.error.result.statusCode === 400) {
            message.error({ content: I18n.t('loginToRemindTip4'), key: toastKey, duration: 3 })
            router.push(getLoginPath())
          }
        } catch (e) {
          console.log(e)
        }
        message.error({ content: I18n.t('system.error'), key: toastKey, duration: 3 })
      })
  }
  return {
    onFavoirite
  }
}
