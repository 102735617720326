import React, { useRef, useState } from 'react';
import { FlatList, View } from 'react-native';

import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import Link from 'next/link';

import CommentComponent from '@components/CommentComponent';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import pubsub from '@lib/scripts/pubsub'
import I18n from '@utils/i18n';

import CommentInput from '../Details/Components/CommentInput';

import styles from './index.module.css';

const containerStyle = {
  paddingBottom: '0'
}

const T = ({ itemData, userStore }) => {
  const [postData, setPostData] = useState(itemData);
  const [visibleInput, setVisibleInput] = useState(''); // 评论、回复的输入框互斥，只能显示其中一个 这里存数据Id
  const [commentInputVisible, setCommentInputVisible] = useState(false);
  const commentInputVisibleRef = useRef(false);
  const fetchPostComment = async (opt, cb) => {
    if (!opt?.res?.id) {
      return
    }
    const { res: { verb } } = opt
    if (verb === 'COMMENT') {
      const { socialUserInfo: { customerId, nickName, avatar } } = userStore;
      const customerMisc = { customerId, nickName, avatar };
      const comment = {
        ...opt.res,
        likeCount: 0,
        replyCount: 0,
        customerMisc
      }
      let comments = [comment, ...postData.comments];
      comments = comments.slice(0, 3);
      const commentCount = postData.commentCount + 1;
      setPostData({
        ...postData,
        commentCount,
        comments
      })
      pubsub.publish('POST_COMMENT_COUNT', {
        postId: postData.id,
        commentCount
      })
    }
    if (verb === 'REPLY') {
      const commentIndex = postData.comments.findIndex(item => item.id === opt.res.targetId);
      postData.comments[commentIndex].replyCount = postData.comments[commentIndex].replyCount + 1;
      setPostData({
        ...postData
      })
    }
    cb?.()
  }
  const updateCommentState = ({ postId }) => {
    if (postId === postData.id) {
      commentInputVisibleRef.current = !commentInputVisibleRef.current
      setCommentInputVisible(commentInputVisibleRef.current)
    }
  }
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('COMMENT_INPUT_STATE', updateCommentState);
    return () => {
      pubsub.unSubscribe('COMMENT_INPUT_STATE', updateCommentState);
    }
  }, [])
  return (
    <>
    <CommentInput itemData={postData} fetchPostComment={fetchPostComment} className={cn(styles.commentInput, { [styles.hidden]: !commentInputVisible })} />
    <FlatList
      data={postData.comments}
      ItemSeparatorComponent={() => {
        return <View />
      }}
      renderItem={({ item, index }) => {
        return (
          <CommentComponent
            containerStyle={postData.commentCount <= 3 ? containerStyle : {}}
            commentClass={styles.comment}
            commentTextClass={styles.commentText}
            actionClass={cn(styles.actionGroup, {
              [styles.visible]: visibleInput === item.id
            })}
            commentData={item}
            visibleInput={visibleInput}
            setVisibleInput={setVisibleInput}
            fetchPostComment={fetchPostComment}
          />
        )
      }}
      keyExtractor={(item, index) => {
        return item.id
      }}
    />
    {
      postData.commentCount > 3 && (
        <Link legacyBehavior href={`/post/${postData.id}`}>
          <a className={cn(styles.moreComment, {
            [styles.indentation]: postData?.comments?.length > 0,
            [styles.indentation10]: !postData?.comments?.length
          })} data-buried="moreComment">
          {I18n.t('moreComment')}
          </a>
        </Link>
      )
    }
    </>
  )
}

export const FeedComment = inject(rootStore => {
  return {
    userStore: rootStore.store.userStore
  }
})(observer(T))
