import React, { useEffect, useMemo, useState } from 'react';

import { withRouter } from 'next/router';

import FeedImage from '@components/FeedImage';
import ImageGroupPreview from '@components/FeedImage/ImageGroupPreview';
import TopicTag from '@components/TopicTag';
import { VideoComponent } from '@element/index';
import { useClient } from '@lib/hooks';
import bugsnag from '@utils/bugsnag';
import helper from '@utils/helper';
import p from '@utils/Transfrom';
import { FeedActionGroup } from '@widget/post/FeedActionGroup';
import { FeedComment } from '@widget/post/FeedComment';

import { HeaderItem } from './components/HeaderItem';

import postStyles from './index.module.css';

export * from './deletePost';

const imageUriParam =
  '?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fill,w_690,h_690/quality,q_90/format,jpg';

const ItemOne = ({ item }) => {
  let imgH = p(360);
  let imgW = p(360);
  const innerWidth = window.innerWidth;
  const theModel = item + imageUriParam;
  const fetchWidth = helper.getImageWidth(theModel, '');
  const fetchHeight = helper.getImageHeight(theModel, '');
  if (fetchWidth / fetchHeight < 0.7) {
    // console.log('长图')
    imgH = p(460);
  }
  if (fetchHeight / fetchWidth < 0.7) {
    // console.log('宽图')
    imgW = p(460);
    if (innerWidth && innerWidth < 576) {
      imgW = innerWidth - 30;
    }
  }
  return (
    <FeedImage
      src={item}
      height={imgH}
      width={imgW}
      className={postStyles.feedImage}
      preview={{
        maskClassName: 'image_borderRadius_5px'
      }}
    />
  );
};

function getImageMode ({ src = '' }) {
  if (!src) return '';
  const img = new Image();
  img.src = src;
  return new Promise((resolve) => {
    img.onload = function () {
      const width = img.width;
      const height = img.height;
      let mode = '';
      if (width / height < 0.9) {
        mode = 'vertical'; // 竖图
      }
      if (height / width < 0.9) {
        mode = 'horizontal'; // 宽图
      }
      resolve(mode);
    };
  });
}

// 单图的宽图定宽，长图定高
const SingleImageAuto = ({ imgSrc = '' }) => {
  const isPc = useClient();
  const [mode, setMode] = useState('');

  useEffect(() => {
    (async () => {
      const mode = await getImageMode({ src: imgSrc });
      setMode(mode);
    })();
  }, [imgSrc]);

  const size = useMemo(() => {
    const defaultSize = {
      width: 180,
      height: 180
    };
    if (mode === 'horizontal') {
      return { ...defaultSize, width: isPc ? 570 : '100%', height: 'auto' };
    }
    if (mode === 'vertical') {
      return { ...defaultSize, width: 'auto', height: isPc ? 400 : 200 };
    }
    return defaultSize;
  }, [isPc, mode]);

  return (
    !!imgSrc && (
      <FeedImage
        src={imgSrc}
        width={size.width}
        height={size.height}
        className={postStyles.feedImage}
        preview={{
          maskClassName: 'image_borderRadius_5px'
        }}
      />
    )
  );
};

// 展示两张及两张以上图片
const ItemThree = ({ images, imagesLength }) => {
  let imgH = p(360);
  let imgW = p(360);
  let marginR = p(32);
  const innerWidth = window.innerWidth;
  if (innerWidth && innerWidth < 576) {
    marginR = 10;
    if (imagesLength === 2) {
      imgW = (innerWidth - 40) / 2;
      imgH = (innerWidth - 40) / 2;
    } else {
      // imagesLength >= 3
      imgW = (innerWidth - 50) / 3;
      imgH = (innerWidth - 50) / 3;
    }
  }

  return (
    <div className={postStyles.itemThreeContainer}>
      {images.map((imageItem, index) => {
        if (index > 2) {
          return null;
        }
        return (
          <div
            key={'images' + index}
            style={{
              marginRight:
                index === images.length - 1 || index === 2 ? 0 : marginR
            }}
          >
            <ImageGroupPreview
              width={imgW}
              height={imgH}
              curSrc={imageItem}
              currentIndex={index}
              previewImgGroup={images}
              imgStyle={{ borderRadius: p(8) }}
              maskClassName="image_borderRadius_4px"
            />
          </div>
        );
      })}
      {imagesLength > 3 ? (
        <div className={postStyles.imgCount}>
          <span className={postStyles.imgCountNum}>+{imagesLength - 3}</span>
        </div>
      ) : null}
    </div>
  );
};

const ItemVideo = ({ videoData }) => {
  const { videoSource, videoThumbnail, videoImages } = videoData;
  const videoH = 200;
  let videoW = 375;
  const innerWidth = window.innerWidth;
  if (innerWidth && innerWidth < 576) {
    videoW = innerWidth - 30;
  }
  if (videoSource) {
    return (
      <VideoComponent
        videoSource={videoSource}
        width={videoW}
        height={videoH}
        poster={videoThumbnail || videoImages?.[0]}
      />
    );
  }
  return null;
};

const FooterItem = ({ itemData }) => {
  return (
    <>
      <FeedActionGroup itemData={itemData} />
      <FeedComment itemData={itemData} />
    </>
  );
};

const PostComponent = (props) => {
  const { itemData, customFooter, router } = props;
  let images = [];
  if (itemData.images) {
    images = [...itemData.images];
  }

  if (itemData.isVideo && !itemData.images?.length) {
    return (
      <div>
        <HeaderItem itemData={itemData} router={router} />
        <div>
          <ItemVideo videoData={itemData} />
        </div>
        <div data-buried="topic" data-buried-type="post-topic">
          <TopicTag itemData={itemData} />
        </div>
        {!customFooter ? (
          <FooterItem itemData={itemData} />
        ) : (
          customFooter(itemData)
        )}
      </div>
    );
  }

  if (itemData?.subVerb === 12 || helper.isHtmlTag(itemData?.text)) {
    try {
      const _images = images.length > 3 ? images.splice(0, 3) : images;
      return (
        <div>
          <HeaderItem itemData={itemData} router={router} />
          {itemData.images && itemData.images.length > 0 ? (
            <div className={postStyles.imgCintainer}>
              {itemData.images.length === 1 ? (
                <ItemOne item={itemData.images[0]} />
              ) : (
                <ItemThree
                  itemData={itemData}
                  images={_images}
                  imagesLength={itemData.images.length}
                />
              )}
            </div>
          ) : null}
          {images.length === 0 && itemData.isVideo ? (
            <div className={postStyles.videoCintainer}>
              <ItemVideo videoData={itemData} />
            </div>
          ) : null}
          <div data-buried="topic" data-buried-type="post-topic">
            <TopicTag itemData={itemData} />
          </div>
          {!customFooter ? (
            <FooterItem itemData={itemData} />
          ) : (
            customFooter(itemData)
          )}
        </div>
      );
    } catch (e) {
      bugsnag.notify(
        new Error(
          '[components-PostComponent] -- [render] -- [error]: ' + e.toString()
        )
      );
      console.log(e);
      return <div />;
    }
  }

  return (
    <div>
      <HeaderItem itemData={itemData} router={router} />
      {itemData.images && itemData.images.length > 0 ? (
        <div className={postStyles.imgCintainer}>
          {itemData.images.length === 1 ? (
            <SingleImageAuto imgSrc={itemData.images[0]} />
          ) : (
            <ItemThree
              itemData={itemData}
              images={itemData.images}
              imagesLength={itemData.images.length}
            />
          )}
        </div>
      ) : null}
      <div data-buried="topic" data-buried-type="post-topic">
        <TopicTag itemData={itemData} />
      </div>
      {!customFooter ? (
        <FooterItem itemData={itemData} />
      ) : (
        customFooter(itemData)
      )}
    </div>
  );
};

export default withRouter(PostComponent);
